import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../Layout/DataTable/DataTable";
import PageHeader from "../../Layout/PageHeader/PageHeader";
import Button from "../../UI/Button/Button";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../utils/CautionAlert";
import axios from "axios";
import backendUrl from "../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../UI/Loader/LoaderWithoutBackdrop";
import Loader from "../../UI/Loader/Loader";
import { checkPrivilage } from "../../utils/checkPrivilage";
import columns from "./../../Resources/jobsColumns";
import Theme from "../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../UI/ActionButton/ActionButtonBox";

const ImmunisationJobs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [switchItem, setSwitchItem] = useState(null);
  const [run, setRun] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/immunisation_jobs`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data?.data) {
          var dt = response.data?.data;
          dt = dt.map((item) => {
            return {
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                          .ActionButton button {
                            background-color:${Theme.palette.primaryColor.backgroundColor};
                          }
                          .ActionButton button:hover {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                          .ActionButton button:focus {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                      `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    <ActionButton
                      content="Re-run"
                      onClickAction={() => setRun(item)}
                      icon="codicon:debug-rerun"
                      eventKey="1"
                    />
                  </DropdownButton>
                </Fragment>
              ),
            };
          });

          setData([...dt]);
          setLoader(false);
        } else {
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  //   const handleDelete = async (id) => {
  //     setDeleteLoader(true);
  //     await axios({
  //       method: "DELETE",
  //       withCredentials: true,
  //       url: `${backendUrl}/${getSite}/api/appointment_types/${id}`,
  //     })
  //       .then((response) => {
  //         setOpen(false);
  //         successHandler(response.data.message);
  //         getData();
  //         setDeleteLoader(false);
  //       })
  //       .catch(function (error) {
  //         setOpen(false);
  //         if (error.response) {
  //           setDeleteLoader(false);
  //           errorHandler("Something went wrong. Please try again");
  //         } else {
  //           setDeleteLoader(false);
  //           errorHandler("Something went wrong. Please try again");
  //         }
  //       });
  //   };

  //   const deleteConfirm = () => {
  //     handleDelete(passIdDelete.id);
  //   };

  //   const handleClose = (value) => {
  //     setOpen(false);
  //   };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/cron-jobs",
      iconName: "mdi:tool-time",
      name: "Jobs Management",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  const names = [
    {job:"AIR VACCINES REF DATA", uri:"/api/manual-air-vaccines-list"},
    {job:"AIR DISEASE REF DATA", uri:"/api/manual-air-disease-list"},
    {job:"AIR VACCINE TYPE REF DATA", uri:"/api/manual-air-vaccine-type-list"},
    {job:"AIR ROUTE OF ADMINISTRATION REF DATA", uri:"/api/manual-air-route-administration-list"},
  ];

  const handleRun = async (job) => {
    setRun(null);
    setLoader(true);

    const uri =names.find((a) => a.job === job)?.uri

    const url = `${backendUrl}${uri}`;
    
    await axios({
      method: "POST",
      withCredentials: true,
      url,
    })
      .then((response) => {
        setData(null)
        loadData();
        setLoader(false);
        setSuccess("Job successfully run");
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  return (
    <Fragment>
      <Loader open={loader} />

      {/* <CautionAlert
        severity="error"
        open={open}
        content="Are you sure you want to delete this item?"
        onCloseActionB={deleteConfirm}
        onCloseActionA={handleClose}
        okText="Delete"
        cancelText="Cancel"
      />
       */}

      {apiError && (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionB={retry}
          onCloseActionA={() => setApiError(null)}
          okText="Retry"
          cancelText="Dismiss"
        />
      )}
      {error && (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      )}
      <CautionAlert
        severity="error"
        open={run}
        content="Are you sure you want to re-run this job?"
        onCloseActionB={() => handleRun(run.job)}
        onCloseActionA={() => setRun(null)}
        okText="Run"
        cancelText="Cancel"
      />
      <CautionAlert
        severity="error"
        open={apiError}
        content={apiError}
        onCloseActionB={() => setApiError(null)}
        okText="Ok"
        oneButton={true}
      />
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/subscription-jobs", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}

      <Breadcrumb options={breadCrumbData} />

      {showTable ? (
        <>
          <PageHeader
            left={"Subscription Jobs"}
            // right={
            //   <Button
            //     // disabled={openAddUser === true ? true : false}
            //     onClickAction={() => navigate("/app/add/test")}
            //     text="Add Test"
            //     startIcon={
            //       <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
            //     }
            //   ></Button>
            // }
          />

          {data && <DataTable data={data} columns={columns.jobTableColumns} />}
        </>
      ) : null}
    </Fragment>
  );
};

export default ImmunisationJobs;
