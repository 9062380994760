import {
    Alert,
    Box,
    Grid,
    ThemeProvider,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  import React, { Fragment, useEffect, useState } from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import Breadcrumb from "../../../Layout/Breadcrumb/Breadcrumb";
  import PageFooter from "../../../Layout/PageFooter/PageFooter";
  import PageHeader from "../../../Layout/PageHeader/PageHeader";
  import Theme from "../../../Theme/Theme";
  import Button from "../../../UI/Button/Button";
  import TextBox from "../../../UI/FormGroup/TextBox/TextBox";
  import Loader from "../../../UI/Loader/Loader";
  import classes from "./../Immune.module.css";
  import axios from "axios";
  import backendUrl from "../../../utils/backendUrl";
  import CautionAlert from "../../../utils/CautionAlert";
  import { useDispatch } from "react-redux";
  export default function AddRoute() {
    const widthIs14 = useMediaQuery("(max-width:1399px)");
    const widthIs930 = useMediaQuery("(max-width:930px)");
    const widthIs1630 = useMediaQuery("(max-width:1630px)");
    const location = useLocation();
  
    const dispatch = useDispatch();
  
    const navigate = useNavigate();
  
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
  
    const [code, setCode] = useState(null);
    const [codeError, setCodeError] = useState(null);
    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [disease, setDisease] = useState();
    const [diseaseError, setDiseaseError] = useState(null);
    const [antiCode, setAntiCode] = useState();
    const [antiCodeError, setAntiCodeError] = useState(null);
  
    const codeHandler = (e) => {
      setCodeError(null);
      setCode(e.target.value);
    };
    const codeValidation = () => {
      if (!code) {
        setCodeError("Required");
        return false;
      }
      return true;
    };
  
    const nameHandler = (e) => {
      setNameError(null);
      setName(e.target.value);
    };
  
    const nameValidation = () => {
      if (!name) {
        setNameError("Required");
        return false;
      }
      return true;
    };
  
  
    async function postData() {
      let body = {
        types: [
          {
            name,
            code,
          },
        ],
      };
  
      const result = await axios
        .post(
          `${backendUrl}/api/route_administration`,
          body,
          { withCredentials: true },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((res) => {
          setLoader(false);
          navigate(`/app/route-administration`, {
            state: {
              showAlert: "Route of administration has been added successfully",
            },
          });
        })
        .catch((e) => {
          setLoader(false);
          setError("Something went wrong. Please try again", true);
        });
  
      return result.data;
    }
  
    const submitValidation = () => {
      let proceed = true;
      proceed = proceed && codeValidation();
      proceed = proceed && nameValidation();
  
      if (proceed) {
        // All validations passed, proceed with submitting the form
        return true;
      } else {
        // Some validations failed, display error messages
        codeValidation();
        nameValidation();
  
        return false;
      }
    };
  
    const submitHandler = async (event) => {
      setLoader(true);
      event.preventDefault();
  
      let isProceed = submitValidation();
  
      if (!isProceed) {
        setLoader(false);
        return false;
      }
  
      await postData();
  
      setLoader(false);
    };
  
    const breadCrumbData = [
      {
        link: "/app",
        iconName: "ant-design:home-filled",
        name: "",
      },
      {
        link: "/app/management",
        iconName: "icon-park-outline:connect",
        name: "Management",
      },
      {
        link: "/app/immunisation",
        iconName: "medical-icon:immunizations",
        name: "Immunisation Management",
      },
      {
        link: "/app/route-administration",
        iconName: "medical-icon:i-immunizations",
        name: "Route of Administration",
      },
    ];
  
    return (
      <ThemeProvider theme={Theme}>
        <Loader open={loader} />
        <Breadcrumb options={breadCrumbData} />
        {error ? (
          <CautionAlert
            severity="error"
            open={error}
            content={error}
            onCloseActionB={() => setError(null)}
            okText="Ok"
            oneButton={true}
          />
        ) : null}
  
        {success ? (
          <CautionAlert
            severity="success"
            success={true}
            open={success}
            content={success}
            onCloseActionB={() => setSuccess(null)}
            okText="Ok"
            oneButton={true}
          />
        ) : null}
  
        <PageHeader left="Add Route of Administration" />
        <Loader open={loader} />
  
        <form>
          <Box className={classes.FormBox}>
            <Grid container direction="row" spacing={2}>
              {/* First Column */}
              <Grid
                container
                item
                xs={widthIs14 ? 12 : 6}
                direction="column"
                sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
                className={classes.Max}
              >
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Name"
                  onChangeAction={nameHandler}
                  value={name}
                  onBlurAction={nameValidation}
                  error={nameError}
                  placeholder="Name"
                  required
                />
                <TextBox
                  xsLabel={4}
                  xsInside={8}
                  type="text"
                  label="Route Code"
                  onChangeAction={codeHandler}
                  value={code}
                  onBlurAction={codeValidation}
                  error={codeError}
                  placeholder="Code"
                  required
                />
              </Grid>
            </Grid>
          </Box>
          <PageFooter
            right={
              <Fragment>
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button
                    text="Submit"
                    // type="submit"
                    onClickAction={submitHandler}
                  />
                </Box>
  
                <Box>
                  <Button
                    fontSize="14px"
                    onMouseDownAction={() => navigate(-1)}
                    bgLight={true}
                    text="Cancel"
                  />
                </Box>
              </Fragment>
            }
          />
        </form>
      </ThemeProvider>
    );
  }
  