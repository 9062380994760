import {
  Alert,
  Box,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../Layout/Breadcrumb/Breadcrumb";
import PageFooter from "../../../Layout/PageFooter/PageFooter";
import PageHeader from "../../../Layout/PageHeader/PageHeader";
import Theme from "../../../Theme/Theme";
import Button from "../../../UI/Button/Button";
import TextBox from "../../../UI/FormGroup/TextBox/TextBox";
import Loader from "../../../UI/Loader/Loader";
import classes from "./../Immune.module.css";
import axios from "axios";
import backendUrl from "../../../utils/backendUrl";
import CautionAlert from "../../../utils/CautionAlert";
import { useDispatch } from "react-redux";
export default function EditVaccineType() {
  const widthIs14 = useMediaQuery("(max-width:1399px)");
  const widthIs930 = useMediaQuery("(max-width:930px)");
  const widthIs1630 = useMediaQuery("(max-width:1630px)");
  const location = useLocation();
  const { id } = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [success, setSuccess] = useState(null);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [noData, setNoData] = useState(true);

  const [code, setCode] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [name, setName] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [disease, setDisease] = useState();
  const [diseaseError, setDiseaseError] = useState(null);
  const [antiCode, setAntiCode] = useState();
  const [antiCodeError, setAntiCodeError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    prefillData();
  }, [data]);

  const loadData = async () => {
    setLoader(true);
    if (location.state && location.state.data) {
      setData(location.state.data);
      setNoData(false);
      setLoader(false);
    } else {
      await axios({
        method: "GET",
        withCredentials: true,
        url: `${backendUrl}/api/vaccine_type?id=${id}`,
      })
        .then(async (gb) => {
          setLoader(false);
          const global = gb.data && gb.data.data?.[0];
          if (!global) {
            throw new Error("Vaccine type not found");
          }

          setData(global);
          setNoData(false);
        })
        .catch((e) => {
          setNoData(true);
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        });
    }
  };

  const prefillData = () => {
    if (data) {
      setName(data.name);
      setCode(data.code);
    }
  };

  const codeHandler = (e) => {
    setCodeError(null);
    setCode(e.target.value);
  };
  const codeValidation = () => {
    if (!code) {
      setCodeError("Required");
      return false;
    }
    return true;
  };

  const nameHandler = (e) => {
    setNameError(null);
    setName(e.target.value);
  };

  const nameValidation = () => {
    if (!name) {
      setNameError("Required");
      return false;
    }
    return true;
  };

  async function postData() {
    let body = {
      name,
      code,
    };

    const result = await axios
      .patch(
        `${backendUrl}/api/vaccine_type/${id}`,
        body,
        { withCredentials: true },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((res) => {
        setLoader(false);
        navigate(`/app/vaccine-type`, {
          state: {
            showAlert: "Vaccine type has been successfully updated",
          },
        });
      })
      .catch((e) => {
        setLoader(false);
        setError("Something went wrong. Please try again", true);
      });

    return result.data;
  }

  const submitValidation = () => {
    if (
      data.name === name &&
      data.vaccine_code === code &&
      data.disease_names === disease &&
      data.antigen_codes === antiCode
    ) {
      setError("You've made no changes");
      return;
    }
    let proceed = true;
    proceed = proceed && codeValidation();
    proceed = proceed && nameValidation();

    if (proceed) {
      // All validations passed, proceed with submitting the form
      return true;
    } else {
      // Some validations failed, display error messages
      codeValidation();
      nameValidation();

      return false;
    }
  };

  const submitHandler = async (event) => {
    setLoader(true);
    event.preventDefault();

    let isProceed = submitValidation();

    if (!isProceed) {
      setLoader(false);
      return false;
    }

    await postData();

    setLoader(false);
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/immunisation",
      iconName: "medical-icon:immunizations",
      name: "Immunisation Management",
    },
    {
      link: "/app/vaccine-type",
      iconName: "material-symbols:vaccines",
      name: "Vaccine Types",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Loader open={loader} />
      <Breadcrumb options={breadCrumbData} />
      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionB={() => setApiError()}
          onCloseActionA={retry}
          okText="Retry"
          cancelText="Dismiss"
        />
      ) : null}
      {error ? (
        <CautionAlert
          severity="error"
          open={error}
          content={error}
          onCloseActionB={() => setError(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      {success ? (
        <CautionAlert
          severity="success"
          success={true}
          open={success}
          content={success}
          onCloseActionB={() => setSuccess(null)}
          okText="Ok"
          oneButton={true}
        />
      ) : null}

      <PageHeader left="Edit Vaccine Type" />
      <Loader open={loader} />

      <form>
        <Box className={classes.FormBox}>
          <Grid container direction="row" spacing={2}>
            {/* First Column */}
            <Grid
              container
              item
              xs={widthIs14 ? 12 : 6}
              direction="column"
              sx={{ maxWidth: widthIs14 ? "" : "590px !important" }}
              className={classes.Max}
            >
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Name"
                onChangeAction={nameHandler}
                value={name}
                onBlurAction={nameValidation}
                error={nameError}
                placeholder="Name"
                required
              />
              <TextBox
                xsLabel={4}
                xsInside={8}
                type="text"
                label="Vaccine Code"
                onChangeAction={codeHandler}
                value={code}
                onBlurAction={codeValidation}
                error={codeError}
                placeholder="Code"
                required
              />
            </Grid>
          </Grid>
        </Box>
        <PageFooter
          right={
            <Fragment>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Button
                  text="Submit"
                  // type="submit"
                  onClickAction={submitHandler}
                />
              </Box>

              <Box>
                <Button
                  fontSize="14px"
                  onMouseDownAction={() => navigate(-1)}
                  bgLight={true}
                  text="Cancel"
                />
              </Box>
            </Fragment>
          }
        />
      </form>
    </ThemeProvider>
  );
}
