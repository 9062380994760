import timestampHandler from "../utils/timstampHandler";

const vaccineTableColumns = [
  {
    text: "Name",
    dataField: "name",
  },
  {
    text: "Vaccine Code",
    dataField: "vaccine_code",
  },
  {
    text: "Disease Names",
    dataField: "disease_names",
  },
  {
    text: "Antigen Codes",
    dataField: "antigen_codes",
  },  
  {
    text: "Active",
    dataField: "active",
    formatter: (value) => {
      return value ? "Yes" : "No";
    },
  },
  {
    text: "Updated at",
    dataField: "updated_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    text: "Action",
    dataField: "action",
  },
];

const vaccineTypeandRouteColumns = [
  {
    text: "Name",
    dataField: "name",
  },
  {
    text: "Code",
    dataField: "code",
  },
  
  {
    text: "Active",
    dataField: "active",
    formatter: (value) => {
      return value ? "Yes" : "No";
    },
  },
  {
    text: "Updated at",
    dataField: "updated_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    text: "Action",
    dataField: "action",
  },
];

const diseaseTableColumns = [
  {
    text: "Name",
    dataField: "name",
  },
  {
    text: "Antigen Code",
    dataField: "antigen_code",
  },
  {
    text: "Active",
    dataField: "active",
    formatter: (value) => {
      return value ? "Yes" : "No";
    },
  },
  {
    text: "Updated at",
    dataField: "updated_at",
    formatter: (value) => {
      return timestampHandler(value, "DD/MM/YYYY");
    },
  },
  {
    text: "Action",
    dataField: "action",
  },
];

export default {
  vaccineTableColumns,
  diseaseTableColumns,
  vaccineTypeandRouteColumns
};
