import React, { Fragment, useEffect, useState } from "react";
import DataTable from "../../../Layout/DataTable/DataTable";
import PageHeader from "../../../Layout/PageHeader/PageHeader";
import Button from "../../../UI/Button/Button";
import { Icon } from "@iconify/react";
import { useNavigate, useLocation } from "react-router-dom";

// Import these files for breadcrumbs
import Breadcrumb from "../../../Layout/Breadcrumb/Breadcrumb";

import CautionAlert from "../../../utils/CautionAlert";
import axios from "axios";
import backendUrl from "../../../utils/backendUrl";
import LoaderWithoutBackdrop from "../../../UI/Loader/LoaderWithoutBackdrop";
import Loader from "../../../UI/Loader/Loader";
import { checkPrivilage } from "../../../utils/checkPrivilage";
import columns from "../../../Resources/ImmunityColumns";
import Theme from "../../../Theme/Theme";
import { DropdownButton } from "react-bootstrap";
import ActionButton from "../../../UI/ActionButton/ActionButtonBox";
import { Box } from "@mui/system";
import UploadPopup from "../UploadPopUp";

const VaccineTypes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [switchItem, setSwitchItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoader(true);
    await axios({
      method: "GET",
      url: `${backendUrl}/api/vaccine_type`,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data?.data) {
          var dt = response.data?.data;
          dt = dt.map((item) => {
            return {
              ...item,
              action: (
                <Fragment>
                  <style type="text/css">
                    {`
                          .ActionButton button {
                            background-color:${Theme.palette.primaryColor.backgroundColor};
                          }
                          .ActionButton button:hover {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                          .ActionButton button:focus {
                            background-color:${Theme.palette.secondaryColor.backgroundColor};
                          }
                      `}
                  </style>
                  <DropdownButton
                    align="end"
                    title="Actions"
                    id="dropdown-menu-align-start"
                    className="ActionButton"
                  >
                    <ActionButton
                      content="Edit"
                      onClickAction={() =>
                        navigate(`/app/edit/vaccine-type/${item.id}`, {
                          state: { data: item },
                        })
                      }
                      icon="ep:edit"
                      eventKey="1"
                    />
                    <ActionButton
                      content="Delete"
                      onClickAction={() => setDeleteItem(item.id)}
                      icon="ant-design:delete-outlined"
                      eventKey="1"
                    />
                  </DropdownButton>
                </Fragment>
              ),
            };
          });

          setData(dt);
          setLoader(false);
        } else {
          setApiError("Something went wrong. Please try again");
          setLoader(false);
        }
      })
      .catch((e) => {
        console.log(e);
        
        setApiError("Something went wrong. Please try again");
        setLoader(false);
      });
  };

  const breadCrumbData = [
    {
      link: "/app",
      iconName: "ant-design:home-filled",
      name: "",
    },
    {
      link: "/app/management",
      iconName: "icon-park-outline:connect",
      name: "Management",
    },
    {
      link: "/app/immunisation",
      iconName: "medical-icon:immunizations",
      name: "Immunisation Management",
    },
    {
      link: "#",
      iconName: "material-symbols:vaccines",
      name: "Vaccine Types",
    },
  ];

  const retry = () => {
    setApiError(null);
    setTimeout(() => {
      loadData();
    }, 100);
  };

  const handleDelete = async (id) => {
    setDeleteItem(null);
    setLoader(true);
    await axios({
      method: "DELETE",
      withCredentials: true,
      url: `${backendUrl}/api/vaccine_type/${id}`,
    })
      .then((response) => {
        setLoader(false);
        setSuccess("Vaccine type has been successfully deleted");
        loadData();
      })
      .catch(function (error) {
        setLoader(false);
        setError("Something went wrong. Please try again");
      });
  };

  const handlePopupClose = () => {
    setOpenUpload(false);
    loadData();
  };

  return (
    <Fragment>
      <Loader open={loader} />
      {/* <UploadPopup
        ama={true}
        title="Upload Medical Contraindication"
        handlePopupOpen={openUpload}
        PopUpOpen={openUpload}
        handlePopupClose={handlePopupClose}
        success={() => setSuccess("Medical contraindication has been successfully uploaded")}
        url={`${backendUrl}/api/med_contra/vaccine/upload`}
      /> */}
      {apiError ? (
        <CautionAlert
          severity="error"
          open={apiError}
          content={apiError}
          onCloseActionA={() => setApiError()}
          onCloseActionB={retry}
          cancelText="Dismiss"
          okText="Retry"
        />
      ) : null}
      <CautionAlert
        severity="error"
        open={deleteItem}
        content="Are you sure you want to delete this record?"
        onCloseActionB={() => handleDelete(deleteItem)}
        onCloseActionA={() => setDeleteItem(null)}
        okText="Delete"
        cancelText="Cancel"
      />
      <CautionAlert
        severity="error"
        open={error}
        content={error}
        onCloseActionB={() => setError(null)}
        okText="Ok"
        oneButton={true}
      />
      <CautionAlert
        severity="success"
        success={true}
        open={success}
        content={success}
        onCloseActionB={() => setSuccess(null)}
        okText="Ok"
        oneButton={true}
      />
      {location.state?.showAlert && (
        <CautionAlert
          severity="success"
          success={true}
          open={location.state.showAlert}
          content={location.state.showAlert}
          onCloseActionB={() =>
            navigate("/app/vaccine-type", location.state.showAlert, {})
          }
          okText="Ok"
          oneButton={true}
        />
      )}

      <Breadcrumb options={breadCrumbData} />

      {showTable ? (
        <>
          <PageHeader
            left={"Vaccine Types"}
            noCenter={true}
            right={
              <>
                <Box
                  sx={{
                    px: 2,
                  }}
                >
                  <Button
                    // disabled={openAddUser === true ? true : false}
                    onClickAction={() => navigate("/app/add/vaccine-type")}
                    text="Add Vaccine Type"
                    startIcon={
                      <Icon
                        style={{ fontSize: "14px" }}
                        icon="akar-icons:plus"
                      />
                    }
                  ></Button>
                </Box>
                {/* <Button
                  // disabled={openAddUser === true ? true : false}
                  onClickAction={() => setOpenUpload(true)}
                  text="Import Med Contra"
                  startIcon={
                    <Icon style={{ fontSize: "14px" }} icon="akar-icons:plus" />
                  }
                ></Button> */}
              </>
            }
          />

          {data && (
            <DataTable data={data} columns={columns.vaccineTypeandRouteColumns} />
          )}
        </>
      ) : null}
    </Fragment>
  );
};

export default VaccineTypes;
